// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require('popper.js');
$ = require('jquery');
window.$ = window.jQuery = $;
require('bootstrap');


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

require('../../assets/stylesheets/stylesheets.js');
const images = require.context('../../assets/images', true)
const imagePath = (name) => images(name, true)

require("trix")
require("@rails/actiontext")

$(document).on('turbolinks:load', function () {


$(document).ready(function (e) {
    document.getElementById("contact-form-submit").addEventListener("click", function (event) {
        event.preventDefault();
        document.getElementById('contact-form').submit()
    });

    document.getElementById("contact-form-submit-key").addEventListener("keydown", function (event) {
        event.preventDefault();
        if (event.code === "Enter") {
            document.getElementById('contact-form').submit();
        }

    });
})

    $(document).ready(function () {

        $("#contact-form").submit(function (e) {

            //stop submitting the form to see the disabled button effect
            e.preventDefault();

            //disable the submit button
            $('#position-response').attr("disabled", true);

            return true;

        });
    });

$(function () {
    "use strict";

    $(".popup img").click(function () {
        var $src = $(this).attr("src");
        $(".show").fadeIn();
        $(".img-show img").attr("src", $src);
    });

    $("span, .overlay").click(function () {
        $(".show").fadeOut();
    });

});
});


